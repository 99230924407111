import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import PeopleIcon from '@material-ui/icons/People';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import LockIcon from '@material-ui/icons/Lock';
import { Link } from "react-router-dom";
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, Divider, ListItem, ListItemText, ListItemIcon, CssBaseline, Hidden, withStyles  }  from '@material-ui/core/';
import { red } from '@material-ui/core/colors';

import NotesIcon from '@material-ui/icons/Notes';
import ImageIcon from '@material-ui/icons/Image';
import { Menu as MenuIcon, AddPhotoAlternate as AddPhotoIcon, Home as HomeIcon, SupervisorAccount as SupervisorAccountIcon, NewReleases as NewReleasesIcon } from '@material-ui/icons/';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import BarChartIcon from '@material-ui/icons/BarChart';
import ReportIcon from '@material-ui/icons/Report';

const useStyles = makeStyles(theme=>({
  root: {
    display: 'flex',
  },
}));
const StyledListItem = withStyles(theme=>({
  root: {
    "&$selected:hover":{
      backgroundColor: red[100],
      color: red[500],
      //color:theme.palette.getContrastText(red[300]),

    },
    "&$selected": {
      backgroundColor: red[200],
      color: red[900],
      //color:theme.palette.getContrastText(red[500]),

    }
  },
  selected: {}
}))(ListItem);

  export default class AdminMenu extends React.Component {
      constructor(props){
          super(props);
          this.state={menuOpen:false,imageMenuOpen:false,userMenuOpen:false,supportMenuOpen:false};
          this.toggleMenu=this.toggleMenu.bind(this);
      }
    toggleMenu(){
        this.setState({imageMenuOpen:false, userMenuOpen:false,supportMenuOpen:false})
    }


    render(){
        const {menuOpen, imageMenuOpen, userMenuOpen, supportMenuOpen } = this.state;
      
        var classes=this.props.classes;
        var app=this.props.app;
        var handleDrawerToggle = this.props.drawerToggle;
        return(
              <div>
                <StyledListItem button key="admin" selected={app.state.unassigned4k>0 || app.state.pendingImages>0 || app.state.pendingProfileImages>0 || app.state.pendingRejects>0 || app.state.newTickets>0 || app.state.respondedTickets>0 ? (true):(false)} onClick={()=>this.setState({menuOpen:!menuOpen})}>
                  <ListItemIcon>
                    <SupervisorAccountIcon/>
                  </ListItemIcon>
                  <ListItemText primary="Admin" />
                  {menuOpen ? <ExpandLess /> : <ExpandMore />}
                </StyledListItem>
                {/* IMAGES MENU */}
                <Collapse in={menuOpen} timeout="auto" unmountOnExit>
                  <StyledListItem selected={app.state.unassigned4k>0 || app.state.pendingImages>0 || app.state.pendingRejects>0 ? (true):(false)}  button key="imageAdmin" className={classes.nested} onClick={()=>{ this.toggleMenu(); this.setState({imageMenuOpen:!imageMenuOpen });}}>
                    <ListItemIcon>
                      <ImageIcon/>
                    </ListItemIcon>
                    <ListItemText primary={<Typography
                      component="span"
                      variant="body2"
                      color="inherit"
                      >Images </Typography>}
                    />
                    {imageMenuOpen ? <ExpandLess /> : <ExpandMore />}
                  </StyledListItem>
                  <Collapse in={imageMenuOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      <StyledListItem selected={app.state.pendingImages>0 ? (true):(false)} button className={classes.nested2} component={Link} to='/admin/pending' onClick={handleDrawerToggle}>
                        <ListItemIcon >
                          <HourglassEmptyIcon/>
                        </ListItemIcon>
                        <ListItemText className={classes.nestedText2} primary={<Typography
                          component="span"
                          variant="body2"
                          color="inherit"
                          >{"Pending Uploads ("+app.state.pendingImages+")"}</Typography>}
                        />
                      </StyledListItem>
                      <ListItem button className={classes.nested2} component={Link} to='/admin/myapproved' onClick={handleDrawerToggle}>
                        <ListItemIcon >
                          <DoneOutlineIcon/>
                        </ListItemIcon>
                        <ListItemText className={classes.nestedText2} primary={<Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                          >{"My Approvals"}</Typography>}
                        />
                      </ListItem>
                      <StyledListItem button selected={app.state.reportedImages>0 ? (true):(false)} className={classes.nested2} component={Link} to='/admin/reported' onClick={handleDrawerToggle}>
                        <ListItemIcon >
                          <ReportIcon/>
                        </ListItemIcon>
                        <ListItemText className={classes.nestedText2} primary={<Typography
                          component="span"
                          variant="body2"
                          color="inherit"
                          >{"Reported Images ("+app.state.reportedImages+")"}</Typography>}
                        />
                      </StyledListItem>
                      {app.state.user && app.state.user.groups.hasOwnProperty("Admin") ? (
                        <StyledListItem selected={app.state.pendingRejects>0 ? (true):(false)}  button className={classes.nested2} component={Link} to='/admin/pending/rejects' onClick={handleDrawerToggle}>
                          <ListItemIcon color="inherit" >
                            <HourglassEmptyIcon color="inherit"/>
                          </ListItemIcon>
                          <ListItemText className={classes.nestedText2} primary={<Typography
                            component="span"
                            variant="body2"
                            color="inherit"
                            >{"Pending rejects ("+app.state.pendingRejects+")"}</Typography>}
                          />
                        </StyledListItem>
                      ):(null)}
                      {app.state.user && app.state.user.groups.hasOwnProperty("Admin") ? (
                        <StyledListItem selected={app.state.unassigned4k>0 ? (true):(false)}  button className={classes.nested2} component={Link} to='/admin/assign4k' onClick={handleDrawerToggle}>
                          <ListItemIcon color="inherit" >
                            <HourglassEmptyIcon color="inherit" />
                          </ListItemIcon>
                          <ListItemText className={classes.nestedText2} primary={<Typography
                            component="span"
                            variant="body2"
                            color="inherit"
                            >{"Unassigned 4K images  ("+app.state.unassigned4k+")"}</Typography>}
                          />
                        </StyledListItem>
                      ):(null)}
                    </List>
                  </Collapse>

                  {/* Users Menu */}
                  <List component="div" disablePadding>
                    <StyledListItem selected={app.state.pendingProfileImages>0 ? (true):(false)} button className={classes.nested} onClick={()=>{ this.toggleMenu(); this.setState({userMenuOpen:!userMenuOpen});}}>
                      <ListItemIcon >
                        <PeopleIcon/>
                      </ListItemIcon>
                      <ListItemText primary={<Typography
                        component="span"
                        variant="body2"
                        color="inherit"
                        >Users </Typography>}/>
                      {userMenuOpen ? <ExpandLess /> : <ExpandMore />}
                    </StyledListItem>
                    <Collapse in={userMenuOpen} timeout="auto" unmountOnExit>
                      {app.state.user && app.state.user.groups.hasOwnProperty("Admin") ? (
                        <div><ListItem button className={classes.nested2} component={Link} to='/admin/users/manage' onClick={handleDrawerToggle}>
                          <ListItemIcon >
                            <PersonAddIcon/>
                          </ListItemIcon>
                          <ListItemText primary={<Typography
                            component="span"
                            variant="body2"
                            color="textPrimary"
                            >Manage</Typography>}
                          />
                        </ListItem>
                        <ListItem button className={classes.nested2} component={Link} to='/admin/users/premium' onClick={handleDrawerToggle}>
                        <ListItemIcon >
                          <PersonAddIcon/>
                        </ListItemIcon>
                        <ListItemText primary={<Typography
                          component="span"
                          variant="body2"
                          color="textPrimary"
                          >Active Premium Users</Typography>}
                        />
                      </ListItem></div>
                      ):(null)}
                      <StyledListItem selected={app.state.pendingProfileImages>0 ? (true):(false)} button className={classes.nested2} component={Link} to='/admin/users/pending_profile' onClick={handleDrawerToggle}>
                        <ListItemIcon >
                          <HourglassEmptyIcon/>
                        </ListItemIcon>
                        <ListItemText className={classes.nestedText2} primary={<Typography
                          component="span"
                          variant="body2"
                          color="inherit"
                          >{"Pending profile pics ("+app.state.pendingProfileImages+")"}</Typography>}/>
                        </StyledListItem>
                      </Collapse>

                      {/* SUPPORT MENU */}
                      {app.state.user && app.state.user.groups.hasOwnProperty("Admin") ? (<div>
                        <StyledListItem selected={app.state.newTickets>0 || app.state.respondedTickets>0 ? (true):(false)} button className={classes.nested} onClick={()=>{ this.toggleMenu(); this.setState({supportMenuOpen:!supportMenuOpen});}}>
                          <ListItemIcon >
                            <HelpOutlineIcon/>
                          </ListItemIcon>
                          <ListItemText primary={<Typography
                            component="span"
                            variant="body2"
                            color="inherit"
                            >Support </Typography>}/>
                          {supportMenuOpen ? <ExpandLess /> : <ExpandMore />}
                        </StyledListItem>
                        <Collapse in={supportMenuOpen} timeout="auto" unmountOnExit>
                          <StyledListItem selected={app.state.newTickets>0 ? (true):(false)} button className={classes.nested2} component={Link} to='/admin/support/0' onClick={handleDrawerToggle}>
                            <ListItemIcon >
                              <HelpOutlineIcon/>
                            </ListItemIcon>
                            <ListItemText className={classes.nestedText2} primary={<Typography
                              component="span"
                              variant="body2"
                              color="inherit"
                              >{"Open tickets ("+app.state.newTickets+")"}</Typography>}/>
                          </StyledListItem>
                          <StyledListItem selected={app.state.respondedTickets>0 ? (true):(false)} button className={classes.nested2} component={Link} to='/admin/support/2' onClick={handleDrawerToggle}>
                            <ListItemIcon >
                              <HelpOutlineIcon/>
                            </ListItemIcon>
                            <ListItemText className={classes.nestedText2} primary={<Typography
                              component="span"
                              variant="body2"
                              color="inherit"
                              >{"Responded Tickets ("+app.state.respondedTickets+")"}</Typography>}/>
                          </StyledListItem>
                          <StyledListItem button className={classes.nested2} component={Link} to='/admin/support/1' onClick={handleDrawerToggle}>
                            <ListItemIcon >
                              <HelpOutlineIcon/>
                            </ListItemIcon>
                            <ListItemText className={classes.nestedText2} primary={<Typography
                              component="span"
                              variant="body2"
                              color="inherit"
                              >{"Awaiting Response ("+app.state.awaitResponseTickets+")"}</Typography>}/>
                          </StyledListItem>
                          <StyledListItem button className={classes.nested2} component={Link} to='/admin/support/3' onClick={handleDrawerToggle}>
                            <ListItemIcon >
                              <HelpOutlineIcon/>
                            </ListItemIcon>
                            <ListItemText className={classes.nestedText2} primary={<Typography
                              component="span"
                              variant="body2"
                              color="inherit"
                              >{"Closed tickets ("+app.state.closedTickets+")"}</Typography>}/>
                          </StyledListItem>
                        </Collapse></div>
                      ):(null)}

                      {/* MISC OPTIONS */}
                      {app.state.user && app.state.user.groups.hasOwnProperty("Admin") ? (
                        <List>
                          <ListItem button className={classes.nested} component={Link} to='/admin/logs' onClick={handleDrawerToggle}>
                            <ListItemIcon >
                              <NotesIcon/>
                            </ListItemIcon>
                            <ListItemText primary={<Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                              >Admin Logs</Typography>}
                            />
                          </ListItem>
                          <ListItem button className={classes.nested} component={Link} to='/admin/appMessage' onClick={handleDrawerToggle}>
                            <ListItemIcon >
                              <AnnouncementIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                              >App Message</Typography>}
                            />
                          </ListItem>
                          <ListItem button className={classes.nested} component={Link} to='/admin/control' onClick={handleDrawerToggle}>
                            <ListItemIcon >
                              <AnnouncementIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                              >Server controls</Typography>}
                            />
                          </ListItem>
                        </List>
                      ):(null)}
                          <ListItem button className={classes.nested} component={Link} to='/admin/stats' onClick={handleDrawerToggle}>
                            <ListItemIcon >
                              <BarChartIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                              >Stats</Typography>}
                            />
                          </ListItem>
                          <ListItem button className={classes.nested} component={Link} to='/admin/connectedUsers' onClick={handleDrawerToggle}>
                            <ListItemIcon >
                              <LockIcon />
                            </ListItemIcon>
                            <ListItemText primary={<Typography
                              component="span"
                              variant="body2"
                              color="textPrimary"
                              >{"Connected users: "+this.props.app.state.connectedClients}</Typography>}
                            />
                          </ListItem>
                    </List>
                  </Collapse>
                  <Divider />
                </div>
              )
    }
  }