import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { CardContent } from '@material-ui/core';
import { Grid } from '@material-ui/core/';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Parser from 'html-react-parser';
import Bane from '../../assets/developer.webp'
import ReactImageAppear from 'react-image-appear';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import Button from '@material-ui/core/Button'

const useStyles = makeStyles(theme=>({
    margin: {
        margin: theme.spacing.unit * 2,
    },
    padding: {
        padding: theme.spacing.unit
    },
    cardGrid: {
        paddingTop: theme.spacing(12),
    
        [theme.breakpoints.up('sm')]: {
          paddingLeft: `250px !important`,
        },
        paddingBottom: theme.spacing(2),
      },
      card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      },
      cardContent: {
        flexGrow: 1,
      },
      image:{
        [theme.breakpoints.up('xs')]:{
            width:'100%',
            height:'auto'
        },
        [theme.breakpoints.up('md')]:{
            width:'25%',
            height:'auto'
        }
      }
}));

export default class ErrorBoundary extends Component {
    constructor(props){
        super(props)
        this.showError=this.showError.bind(this)
    }
  state = {
    error: '',
    errorInfo: '',
    hasError: false,
    eventId: '',
  };
  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.log({ error, errorInfo });
    this.setState({ errorInfo });
    Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId, errorInfo });
    });
    //this.props.app.setState({hasError:true})
  }

  showError(){
    const { error, errorInfo } = this.state;
    const classes=useStyles();
console.log(errorInfo)
console.log(error.message);
    let errormsg= error.message.split('  ')
    errormsg=errormsg.join('<br>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')
    return (
        <Container className={classes.cardGrid} maxWidth="xl">
            <Card>
                <CardHeader style={{ textAlign: 'center' }} title="OH NO!!! You caught our developer sleeping on the job!" subheader="No worries, we've sent an alarm and let him know about this error. If you wish to provide feedback about how this error occured, click the button below"></CardHeader>
                <Grid container spacing={1}>
                    <Card>
                        
                        <CardContent style={{ textAlign: 'center' }}>
                        <Button color="primary" variant="contained" onClick={() =>Sentry.showReportDialog({ eventId: this.state.eventId })}>Provide feedback</Button><br/><br/>
                        <img
                            placeholder
                            src={Bane}
                            className={classes.image}
                            animation="bounceIn"
                            animationDuration="1s"
                            /><br/>
                            
                            {/* <details className="error-details">
                                <summary style={{ cursor: 'pointer', color: '#0077FF' }}>Click for error details</summary>
                                {error.message}<pre>{errorInfo && errorInfo.componentStack.toString()}</pre>
                            </details> */}
                         </CardContent>
                    </Card>
                </Grid>
        </Card>
        </Container>
      );
  }
  render() {
    // next code block goes here
    const { hasError } = this.state;
if (hasError) {
    return(<this.showError></this.showError>)

}
  return this.props.children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.oneOfType([ PropTypes.object, PropTypes.array ]).isRequired,
};