import React, { Component } from 'react';
import { Grid, Paper, TextField } from '@material-ui/core/';
import { List, ListItem, ListItemText, ListItemIcon }  from '@material-ui/core/';
import { Link } from "react-router-dom";
import Image from 'material-ui-image'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
export class Autocomplete extends Component {
  static propTypes = {};
  constructor(props) {
    super(props);
    this.state = {
        suggestions:[],
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: '',
      anchorEl:null
    };
    this.counter=0;
  }
  static defaultProperty={
    suggestions: []
  };

  processResults=(data)=>{
    console.log("Received results",data)
    var filteredSuggestions=[];
    data.result.images.map((item)=>{
      var itemdetails ={};
      itemdetails.name=item.name.toLowerCase();
      itemdetails.img=item.element_url;
      itemdetails.id=item.id;
      filteredSuggestions.push(itemdetails)
      return null;
  })
  this.setState({
    total:data.result.paging.count,
    activeSuggestion: 0,
    filteredSuggestions,
    showSuggestions: true,
  });
  }

  onChange = (e) => {
    var currCallback=`appBarSearch${this.counter}`;
    this.props.app.socket.removeListener(currCallback.toString());
    this.counter++;
    var newCallback=`appBarSearch${this.counter}`;
    this.props.app.socket.on(`${newCallback.toString()}`,data=>this.processResults(data))

    this.props.app.socket.emit("tmx.images.search",{term:e.currentTarget.value,per_page:5,page:0,callback:newCallback.toString()})
    const userInput = e.currentTarget.value;
    var self=this;
    self.setState({
        userInput: userInput,
        anchorEl:e.currentTarget
      });
   };

    goToImg = (e,name) => {
        const self=this;
        e();
        console.log("Onclick called")
        this.setState({
            activeSuggestion: 0,
            showSuggestions: false,
            userInput: name
        });
        fetch('https://thememyxbox.net/ws_react.php?format=json&method=&method=pwg.images.search&per_page=5&query='+name,{
            credentials: 'include',
            method:'POST'
        })
        .then(res => res.json())
        .then(function(res){
            console.log(res);
            if(res.stat==="ok"){
                var filteredSuggestions=[];
                res.result.images.map((item)=>{
                    var itemdetails ={};
                    itemdetails.name=item.name.toLowerCase();
                    itemdetails.img=item.derivatives.small.url;
                    itemdetails.id=item.id;
                    filteredSuggestions.push(itemdetails)
                    return null;
                })
    
                console.log(filteredSuggestions);
                  self.setState({
                        total:res.result.paging.total_count,
                        activeSuggestion: 0,
                        filteredSuggestions,
                        showSuggestions: false,
                  });
            }else{
                self.setState({
                    loading: false,
                    error:true,
                    errorMsg:"An error occurred retrieving the ticket. The error was: "+res.message
                  });
            }
        },(error)=>{
            console.log(error)
            var msg="An error occured contacting the TMX server"
            if(self.props.app.state.isOffline){
              msg="App is running in offline mode, this content is currently unavailable."
            }
            self.setState({
              loading: false,
              error:true,
              errorMsg:msg
            });
        })
    
    };

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion]
      });
    }
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

    onBlur = e => {
        this.setState({showSuggestions: false})
    }
    onFocus = e => {
        if(this.state.userInput){
            this.setState({showSuggestions: true})
        }
    }
  render(){
    const {
        onChange,
        goToImg,
        onKeyDown,
        onFocus,
        state: {
          filteredSuggestions,
          showSuggestions,
          userInput,
          total
        }
      } = this;
      var self = this;
    let suggestionsListComponent;
    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
            <Paper style={{maxHeight: 200, overflow: 'auto'}} zIndex="modal">
            <List>

        
            {filteredSuggestions.map((suggestion, index) => {
              console.log(suggestion)
              return (
                <ListItem key="recent" button component={Link} to={'/image/'+suggestion.id} onClick={()=>goToImg(self.props.toggle,suggestion.name)}>
                <ListItemIcon>
                <Image
                    color='transparent'
                    src={"https://thememyxbox.net/towebp.php?src="+suggestion.img}
                    aspectRatio={16/9}
                    //onClick={()=>multiSelect(props.index,card.id)}
                    style={{cursor:'pointer',width:'100%'}}
                    />
                </ListItemIcon>
                <ListItemText primary={suggestion.name} />
              </ListItem>
                // <li key={suggestion} onClick={()=>onClick}>
                //   <img src={suggestion.img}></img>{suggestion.name}
                // </li>
              );
            })}
                <ListItem key="recent" button component={Link} to={'/search/'+userInput} onClick={()=>goToImg(self.props.toggle,userInput)}>
                <ListItemIcon>
                    <MoreHorizIcon/>
                </ListItemIcon>
                <ListItemText primary={"More results ("+ total +")"} />
              </ListItem>
          </List>
          </Paper>
        );
      } else {
        suggestionsListComponent = (
          <div class="no-suggestions">
            <em>No suggestions!</em>
          </div>
        );
      }
    }else{
       // console.log(userInput);
    }
    return (
       <React.Fragment>
           <Grid container>
               <Grid xs={12} spacing={0}>
        <TextField label="Search" name="pass" fullWidth autoComplete="new-password" defaultValue=""
            //onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={userInput}
            onFocus={onFocus}
            type="text"/>
         </Grid>
         <Grid xs={12}>
         {suggestionsListComponent}</Grid>
         </Grid>
       </React.Fragment>
     );
 };
}
export default Autocomplete;