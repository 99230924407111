import * as React from "react";
import { ListItem, ListItemIcon} from '@material-ui/core';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import Collapse from '@material-ui/core/Collapse';
import PhotoLibraryIcon from'@material-ui/icons/PhotoLibrary'

const useStyles = makeStyles(theme=>({
    placeHolder:{
        width: theme.spacing(30),
        height: theme.spacing(5),
    },
    nested: {
      paddingLeft: theme.spacing(4),
  },
}));

export default class GetCats extends React.Component{
    constructor(props){
      super(props)
      this.cats=[]
      this.state={logginState:false}
      this.collapseMenu=this.collapseMenu.bind(this);
      this.props.app.socket.on('catMenu', data=>this.menuUpdate(data))
    }

    componentWillMount(){
      this.props.app.catListener=this;
      this.setState({
        app:this.props.app,
        isLoaded:false,
        logginState:this.props.app.state.isLoggedin
      });
      //this.props.app.socket.emit('tmx.categories.getlist',null)
    }

    componentDidMount(){
    }

    componentWillReceiveProps(newProps){
      if(newProps.app.state.isOffline!==this.state.isOffline){
      this.setState({logginState:newProps.app.state.isOffline})
      }
    }

    menuUpdate=(data)=>{
      var date =new Date()

      this.setState({
        isLoaded: true,
        items: data,
        key: date
      });
    }

    placeHolder(){
      const classes=useStyles();
      const items=[1,2,3,4,5]
      return(
        items.map((category, index) => (
          <ListItem key={"appBar"+category}>
              <Skeleton variant="rect"  className={classes.placeHolder} />
          </ListItem>
        ))
      )
  }

  item(props){
    const classes=useStyles();
    var cat=props.cat;
    var app=props.app;
    var mobileOpen=app.state.mobileOpen;
    const handleDrawerToggle = () => {
      app.setMobileOpen(!mobileOpen);
    };

    if(!cat.privatealbum){
      return(<ListItem button component={Link} className={classes.nested} to={'/categories/'+cat.id} key={cat.id} onClick={handleDrawerToggle}>
      <ListItemText primary={cat.name} />
    </ListItem>)
    }else{
      return(<ListItem button component={Link} className={classes.nested} to={'/categories/images/'+cat.id} key={cat.id}  onClick={handleDrawerToggle}>
      <ListItemText primary={cat.name}/>
    </ListItem>)
    }
  }

  collapseMenu(props){
    const [albumMenuOpen, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!albumMenuOpen);
    };
    const { items } = this.state;
    return(<div><ListItem button onClick={handleClick}>
            <ListItemIcon>
              <PhotoLibraryIcon/>
            </ListItemIcon>
          <ListItemText primary="Albums" />
          {albumMenuOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={albumMenuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {items.map((category, index) => (
              <this.item cat={category} app={this.props.app}></this.item>
            ))}
          </List>
      </Collapse></div>)
  }

    render(){
      const { isLoaded } = this.state;

      if(isLoaded){
        return(
          <this.collapseMenu></this.collapseMenu>)
      }else{
        return(<this.placeHolder></this.placeHolder>)
      }
    }
  
  }