import React, { useState, useEffect, Suspense, lazy } from 'react';
import PublicRoutes from './publicRoutes'
import AdminRoutes from './adminRoutes'
import ModRoutes from './modRoutes'
import PrivateRoutes from './privateRoutes'








{/* Errors */}
{/* <Route path="/401" key="*" app={this} component={Err401}/>
<Route path="*" key="*" app={this} component={Err404}/> */}

function merged_arrays(){

    return [].concat(AdminRoutes,ModRoutes,PrivateRoutes,PublicRoutes)
}


export default merged_arrays()