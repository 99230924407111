import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';
import ErrorBoundary from '../errorBoundary';
ReactGA.initialize('UA-163533542-1');
export default function RouteWrapper({   
    component: Component,   
    isPrivate,
    isAdminOnly,
    isModOnly,
    app,  
    ...props 
  }) {   
    //app.setState({hasError:false})
    const signed = app.state.isLoggedin;  
    const isAdmin = app.state.user.groups.hasOwnProperty("Admin");  
    const isMod = app.state.user.groups.hasOwnProperty("Moderator");  
    if(app.state.location!==props.path){
    //app.setState({location:props.path})
    }
    //app.random(15);
    /**    
    * Redirect user to SignIn page if he tries to access a private      route
    * without authentication.    
    */

    if (isAdminOnly && !isAdmin) {     
      return <Redirect to="/401" />;   
    } 
    
    if (isModOnly && !isMod) {     
      return <Redirect to="/401" />;   
    } 

    if (isPrivate && !signed) {     
      return <Redirect to={'/user/login/?redirect='+props.path} />;   
    }    
    /**    
    * Redirect user to Main page if he tries to access a non private route    
    * (SignIn or SignUp) after being authenticated.    
    */   
    if (!isPrivate && signed) {     
      //return <Redirect to="/" />;   
    }   
  
  /**    
  * If not included on both previous cases, redirect user to the desired route.    
  */   

  ReactGA.pageview(window.location.pathname + window.location.search);
  return <Route
  render={(routeprops) => <ErrorBoundary><Component {...props} {...routeprops} app={app}/></ErrorBoundary>}/>; 

  
}
RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isAdminOnly: PropTypes.bool,
  isModOnly: PropTypes.bool,
  app:PropTypes.oneOfType([PropTypes.element, PropTypes.func])
 ,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};
RouteWrapper.defaultProps = {
  isPrivate: false,
  isAdminOnly: false,
  isModOnly: false,
};