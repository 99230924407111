import React, { useState, useEffect, Suspense, lazy, Children } from 'react';
import { Component } from 'react';
import ReactDOM from 'react-dom';
import config from './config.json'
import { Switch, Router } from 'react-router-dom';
import { Snackbar, createMuiTheme, ThemeProvider, CssBaseline } from '@material-ui/core';
import AddToHomescreen from 'react-add-to-homescreen';
import MuiAlert from '@material-ui/lab/Alert';
import { detect } from'detect-browser';
//Import our services
import Route from './helpers/routeWrapper';
import history from './helpers/history';
import ScrollToTop from './classes/scrollTop'

import ErrorBoundary from './helpers/errorBoundary';
import './index.css';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

//Import our custom components
// import PrimaryMenuBar from './components/appmenu/appbar';

import PrimaryMenuBar from './menu';


import CircularProgress from '@material-ui/core/CircularProgress';

import { green } from '@material-ui/core/colors';
import socketIOClient from "socket.io-client";
import routes from './routes/index'

const Recent = lazy(()=> import('./galleryViews/recent'));
const Popular = lazy(()=> import('./galleryViews/popular'));
const Categories= lazy(()=> import('./galleryViews/categories/main'));
const SubCategories= lazy(()=> import( './galleryViews/categories/subcats'));
const Images = lazy(()=> import('./galleryViews/categories/imageLists'));


const Home= lazy(()=> import('./components/home'));
const UserImages= lazy(()=> import('./components/user_images'));
const Image= lazy(()=> import('./components/image'));
const MyFeed= lazy(()=> import( './components/myfeed'));



let ENDPOINT = "https://connect.thememyxbox.net"; //Production
let debug=false;
if (process.env.NODE_ENV !== 'production') {
  debug=true;
  ENDPOINT = "http://localhost:9002"; //Dev
}


const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: green,
  },
  typography: {
    subtitle1: {
      fontSize: 12,
    }
  },
});

const useStyles = makeStyles(theme => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  adContainer:{
      textAlign: 'center',
      [theme.breakpoints.up('md')]: {
          paddingLeft: `250px !important`,
        },
      [theme.breakpoints.up('sm')]: {
          paddingLeft: `250px !important`,
      },
  },
  cardGrid: {
      paddingTop: theme.spacing(12),
  
      [theme.breakpoints.up('sm')]: {
        paddingLeft: `250px !important`,
      },
      paddingBottom: theme.spacing(2),
  },
  cardGridMobile: {

      marginTop: theme.spacing(10),
      [theme.breakpoints.up('xs')]: {
          marginLeft: `0px !important`,
      },
      [theme.breakpoints.up('sm')]: {
      },

  },
  heroContent: {
      position: 'relative',

      width:'20%',
      backgroundColor:'transparent',
      [theme.breakpoints.up('xs')]: {
          marginTop:'-10%',
          marginRight:'2%',
          width:'35%',
      },
      [theme.breakpoints.up('sm')]: {
          marginTop:'-10%',
          marginRight:'-1%',
          width:'38%',
      },
      [theme.breakpoints.up('md')]: {
          marginTop:'-10%',
          marginRight:'5%',
          width:'30%',
      },
      [theme.breakpoints.up('lg')]: {
          marginTop:'-10%',
          marginRight:'7%',
          width:'25%',
      }
    },
    storeButton:{
        width:'20%'
    },
    alert:{
      marginTop: theme.spacing(10),
      [theme.breakpoints.up('xs')]: {
          width: '100%',
      },
      [theme.breakpoints.up('sm')]: {
          marginLeft: `250px !important`,
          width: 'calc(100% - 270px)',
      },

    },
    alertMobile:{
      marginTop: theme.spacing(10),
      width: '100%',
    }
}));

class ErrorBoundComp extends Component { 
  constructor(){

  }
  render(){
  return(
    <ErrorBoundary>
      {this.props.children}
    </ErrorBoundary>
  )
  }
}

const requestNotificationPermission = async () => {
  const permission = await window.Notification.requestPermission()
  // value of permission can be 'granted', 'default', 'denied'
  // granted: user has accepted the request
  // default: user has dismissed the notification permission popup by clicking on x
  // denied: user has denied the request.
  if (permission !== 'granted') {
    //throw new Error('Permission not granted for Notification')
  }
}

const handleAddToHomescreenClick = () => {
  alert(`
    1. Open Share menu
    2. Tap on "Add to Home Screen" button`);
};

// const socket = socketIOClient(ENDPOINT,{
//   transports: ['websocket'],
// });


export default class App extends Component {
  constructor(props){
    super(props);
    this.config=config;
    this.state = {
      version:"loading",
      connected:false,
      location:window.location.pathname, 
      reboot:false, rebootMessage:'', 
      clientID:localStorage.getItem("clientID") || null, 
      isLoggedin:false,
      isOffline:true, 
      connectedClients:0, 
      pendingImages:0,
      unassigned4k:0, 
      pendingProfileImages:0, 
      closedTickets:0, 
      awaitResponseTickets:0, 
      newTickets:0, 
      respondedTickets:0, 
      reportedImages:0,
      user:{
        username:"Guest",
        status:"guest", 
        groups:[], 
        privatealbum:0,
        returned:false
      },
      servername:"DISCONECTED",
      isLoaded:false,
      mobileOpen:false,
      toast:false, 
      isPremium:false,
      uniqueKey:"ABCEW"
    };
    this.textAlert=""; 
    this.logIn = this.logIn.bind(this); 
    this.logOut=this.logOut.bind(this);
    //this.checkStatus=this.checkStatus.bind(this);

    this.setMobileOpen=this.setMobileOpen.bind(this);
    this.handleClose=this.handleClose.bind(this);
    this.alertText=this.alertText.bind(this);
    this.sessionUpdate=this.sessionUpdate.bind(this);
    this.browser= detect();
    //this.serverCheck=this.serverCheck.bind(this);

    this.debug=debug;
    //this.checkInterval=setInterval(this.serverCheck,15000);
    this.socket=socketIOClient(ENDPOINT,{
      query: {authId: this.state.clientID,type:`Website - ${this.browser.name} | Version: ${this.browser.version} | OS: ${this.browser.os}`, },
      transports: ['websocket','polling'],
    });
    this.socket.on('serverStatus', data => {this.serverStatus(data)});
    this.socket.on('session', data => {this.sessionUpdate(data)});
    this.socket.on('serverError', data => {this.showError(data)});
    this.socket.on('sessionId', data => {this.saveSession(data)});
    this.socket.on('connect', data=> this.checkAuth());
    this.socket.on('disconnect', data=> this.appOffline())
    this.socket.on('logoutSuccess', data=> this.doLogout(data))
    this.socket.on('stats', data=> this.stats(data))
    this.socket.on('connect_error', data=> this.appOffline());
    this.socket.on('serverReboot',data=>this.serverReboot(data))
  }

  async getData() {
    const res = await fetch("/xbuild.json");
    const data = await res.json();
    console.log(data);
    return this.setState({ version:data.version.split('-')[4] });
  }

  componentDidMount(){
    this.getData();
    this.debug && console.log(`Connected to`,ENDPOINT)
    this.setState({isLoaded:true})
  }


  serverReboot=(data)=>{
    if(data.status==="aborted"){
      this.textAlert="Server reboot cancelled"
      this.setState({toast:true,toastSeverity:"success",reboot:false})
    }else{
      this.setState({reboot:true,rebootMessage:`Attention: Server will be rebooting in ${data.countdown} at ${this.formatDate(data.when)}`})
    }
  }
  formatDate(date) {
    var date = new Date(date)
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return (date.getMonth() + 1) + "/" + date.getDate() + "/" + date.getFullYear() + "  " + strTime +"";
  }
  legacyLogin=(data)=>{
      //we're gonna log the user into the socket using the legacy method
      this.socket.emit("tmx.user.legacyLogin",data)
  }  

  checkLegacy=()=>{
    var self=this;
    fetch('https://thememyxbox.net/ws_react.php?format=json&method=pwg.session.getStatus',{
      credentials: 'include',
    })
    .then(res => res.json())
    .then((result) => {
      if(result.stat==="ok"){
        if(result.result.status!=="guest"){
          //console.log("Performing legacy login")
          self.legacyLogin(result.result.pwg_token);
        }
      }
    },
    (error) => {
    }
  )

  }

  checkAuth=()=>{
    if(this.state.clientID!==null){
      //this.socket.emit("authorize",{authId:this.state.clientID,type:"Website"})
    }
    this.setState({reboot:false,isOffline:false});
    this.debug && console.log("Client id",{authId:this.state.clientID,type:"Website"})
  }

  appOffline=()=>{
    this.setState({
      offlineMsg:"Connection to server is unavailable. Functionality limited",
      user:{username:"Guest", status:"guest", groups:[], privatealbum:0,returned:false},
      isOffline:true,
      isLoggedin:false,
      isLoaded:true
    });
    console.log("App offline")
  }

  Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  alertText(){
    return this.textAlert;
  }
  componentDidUpdate(prevProps, prevState){
    if(this.state.isOffline!==prevState.isOffline){
      if(this.state.clientID!==null){
        //this.socket.emit("authorize",{authId:this.state.clientID,type:"Website"})
      }
    }
    if(prevState.location!==this.state.location){
      this.random()
    }
  }

  showError=(data)=>{
    this.textAlert=data.message;
    this.setState({toast:true,toastSeverity:"error"})
  }

  saveSession=(data)=>{
    console.log("Session saved")
    localStorage.setItem('clientID', data);
    this.setState({clientID:data})

  }

  stats=(data)=>{
    this.setState({
      pendingImages:data.pendings,
      pendingRejects:data.pendingRejects,
      pendingProfileImages:data.pendingProfileImages,
      newTickets:data.newTickets,
      respondedTickets:data.respondedTickets,
      awaitResponseTickets:data.awaitResponseTickets,
      unassigned4k:data.unassigned4k,
      connectedClients:data.connected,
      reportedImages:data.reportedimage,
      servername:data.serverName
      // closedTickets:res.result.closedTickets
    });
  }

  serverStatus=(data)=>{
          if(data.online===0){
            this.setState({
              offlineMsg:"Maintenance mode: "+data.message+" Functionality limited",
              isOffline:true,
              user:{username:"Guest", status:"guest", groups:[], privatealbum:0,returned:false},
              isLoggedin:false,
              
            });
          }else{

            this.setState({
              offlineMsg:"",
              isOffline:false,
            });
          }
  }

  setMobileOpen(res){
    const isMobile = window.innerWidth < 600;

    if(isMobile){
    this.setState({mobileOpen:res})
    }
  }


  sessionUpdate=(data)=>{
    this.debug && console.log("Session data",data)
    data.returned=true;
    if(!data.isLoggedIn){
      this.checkLegacy();
    }

    this.setState({
      isLoaded: true,
      isLoggedin:data.isLoggedIn,
      user: data,
      returned:true,
      alertText:"",
      //isOffline:false,
      isPremium:false,
    });
    if(this.state.loginPending===true){
      console.log("Valid login, redirecting to",this.state.redirect)
      history.replace(this.state.redirect);
      this.setState({loginPending:false,redirect:"/"})
    }
  }
  

handleClose(event, reason) {
  if (reason === 'clickaway') {
    return;
  }

  this.setState({toast:false});
};

  doLogout=(data)=>{
    this.textAlert="Goodbye, "+this.state.user.username
    localStorage.removeItem("clientID")
    history.replace('/');
    this.setState({loggedIn: false,clientID:false,toast:true,toastSeverity:"success",alertText:"Successfully Signed Out",isPremium:false}); 
  }

  logOut(){
    this.textAlert="Goodbye, "+this.state.user.username

    history.replace('/');
    this.setState({loggedIn: false,toast:true,toastSeverity:"success",alertText:"Successfully Signed Out",isPremium:false}); 
    this.checkStatus();
  }

  logIn(){
    this.setState({loggedIn: true,loginPending:true,}); 
    console.log("Logged in")
    //history.goBack();
    this.checkStatus();
  }

  random=(num)=>{
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var i = 0;
    while (i < num) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
        i++;
    }
    this.setState({uniqueKey: text});
    return null
  }

  loaderPlace=()=>{
    const classes=useStyles();
    return(<Container maxWidth="xl" style={{textAlign:"center",paddingLeft: `250px !important`}} className={classes.cardGrid}><CircularProgress size="25em"/><br/>Loading... Please wait</Container>)
  }



  render() {
    const {isLoaded} = this.state;
      return(      <React.Fragment>  <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <main>
        <AddToHomescreen onAddToHomescreenClick={handleAddToHomescreenClick} />
        <Router history={history} onChange={()=>this.random(12)}>
          <PrimaryMenuBar app={this}/>
          <ScrollToTop>
          <Suspense fallback={<div>Loading...</div>}>
            {this.state.user.returned!==false ? (
          <Switch>
                {/* Public access */}
                {routes.map((route,index)=>{
                  return(<Route app={this} path={route.path} key={index} exact={route.exact} component={route.component} isAdminOnly={route.isAdminOnly} isModOnly={route.isModOnly} isPrivate={route.isPrivate} />)
                }
                )}

            </Switch>
            ):(<this.loaderPlace></this.loaderPlace>)}</Suspense></ScrollToTop>
        </Router>
            
        <Snackbar open={this.state.toast} autoHideDuration={6000} onClose={this.handleClose}>
          <this.Alert onClose={this.handleClose} severity={this.state.toastSeverity}>
            <this.alertText></this.alertText>
          </this.Alert>
        </Snackbar>
        <Snackbar open={this.state.isOffline}>
          <this.Alert severity="warning">
            {this.state.offlineMsg}
          </this.Alert>
        </Snackbar>
        <Snackbar open={this.state.reboot}>
          <this.Alert severity="warning">
            {this.state.rebootMessage}
          </this.Alert>
        </Snackbar>
        </main>
              {/* Footer */}
              </ThemeProvider>
              {/* End footer */}
            </React.Fragment>
  )
//     }else{
//       return(      <React.Fragment>  <ThemeProvider theme={darkTheme}>
//         <CssBaseline />
//         <main>
//         <AddToHomescreen onAddToHomescreenClick={handleAddToHomescreenClick} />
//         <Router history={history} onChange={()=>this.random(12)}>
//           <PrimaryMenuBar app={this}/>
//           <ErrorBoundary>
//           <ScrollToTop>
// <this.loaderPlace></this.loaderPlace>
// </ScrollToTop></ErrorBoundary>
//         </Router>
            
//         </main>
//               {/* Footer */}
//               </ThemeProvider>
//               {/* End footer */}
//             </React.Fragment>
//   )
//     }
    

  }

  // render() {
  //   const {isLoaded} = this.state;

  //   return (
  //     <React.Fragment>  <ThemeProvider theme={darkTheme}>
  //     <CssBaseline />
  //     <main>
  //     <AddToHomescreen onAddToHomescreenClick={handleAddToHomescreenClick} />
  //     <Router history={history} onChange={()=>this.random(12)}>
  //       <PrimaryMenuBar app={this}/>
  //       <ErrorBoundary>
  //       <ScrollToTop>
  //       <Suspense fallback={<div>Loading...</div>}>
  //         {!this.state.isOffline && this.state.user.returned!=="adf" ? (
  //       <Switch>
  //             {/* Public access */}
  //             <Route path="/" key="/" exact app={this} component={Home} />
  //             <Route path="/categorielist" key="/categorielist" app={this} exact component={Categories}/>
  //             <Route path="/categories/:id" app={this} exact component={SubCategories}/>
  //             <Route path="/news" key="/news" app={this} exact component={News}/>
  //             <Route path="/recent" key="/recent" app={this} exact component={Recent}/>
  //             <Route path="/popular" key="/popular" app={this} exact component={Popular}/>
  //             <Route path="/categories/images/:id" key="/categories/images/:id" app={this} component={Images}/>
  //             <Route path="/categories/:id/image/:imgid" key="/categories/:id/image/:imgid" app={this} component={Image}/>
  //             <Route path="/search/:term" key="/search/:term" app={this} component={Search}/>
  //             <Route path="/image/:imgid" key="/image/:imgid" exact app={this} component={Image}/>
  //             <Route path="/user/login" key="/user/login" app={this} component={Login}/>
  //             <Route path="/user/password_reset" key="/user/password_reset" exact app={this} component={PwReset}/>
  //             <Route path="/user/password_reset/:resetkey" key="/user/password_reset/:resetkey" app={this} component={PwReset2}/>
  //             <Route path="/user/register" key="/user/register" app={this} component={Register}/>
  //             <Route path="/user/images/:id" key="/user/images/:id" app={this} component={UserImages}/>
  //             <Route path="/user/sso/:ssoKey/:location" key="/user/sso/:ssoKey/:location" app={this} component={SSO}/>
  //             <Route path="/donate" key="/donate" app={this} component={Donate}/>
  //             <Route path="/about" key="/about" app={this} component={About}/>
  //             <Route path="/top" key="/top" app={this} component={TopContribs}/>

  //             {/* Registered user access */}
  //             <Route path="/user/profile/" key="/user/profile/" exact app={this} component={Profile} isPrivate/> 
  //             <Route path="/user/profile/:location" key="/user/profile/:location" app={this} component={Profile} isPrivate/>
  //             <Route path="/upload" key="/upload" exact app={this} component={Upload} isPrivate/>
  //             <Route path="/favorites" key="/favorites" exact app={this} component={Favorites} isPrivate/>
  //             <Route path="/support" key="/support" exact app={this} component={SupportUser} isPrivate/>
  //             <Route path="/support/:id" key="/support/:id" exact app={this} component={SupportUserTicket} isPrivate/>

  //             {/* Mod level access */}
  //             <Route path="/admin/users/pending_profile" key="/admin/users/pending_profile" exact app={this} component={PendingProfiles} isModOnly/>
  //             <Route path="/admin/pending" key="/admin/pending" exact app={this} component={Pending} isModOnly/>
  //             <Route path="/admin/pending" key="/admin/pending" exact app={this} component={Pending} isModOnly/>
  //             <Route path="/admin/reported" key="/admin/reported" exact app={this} component={Reported}/>
  //             <Route path="/admin/stats" key="/admin/stats" exact app={this} component={Stats} isModOnly/>
  //             <Route path="/admin/connectedUsers" key="/admin/connectedUsers" exact app={this} component={ConnectedList} isModOnly/>
  //             <Route path="/admin/reported/:imgid" key="/admin/reported/:imgid" app={this} component={ReportedView} isModOnly/>


  //             {/* Admin level access */}
  //             <Route path="/admin/support/:id" key="/admin/support/:id" exact app={this} component={SupportAdmin} isAdminOnly/>
  //             <Route path="/admin/support/manage/:id" key="/admin/support/manage/:id" exact app={this} component={TicketAdmin} isAdminOnly/>
  //             <Route path="/admin/assign4k" key="/admin/assign4k" exact app={this} component={FourKImages} isAdminOnly/>
  //             <Route path="/admin/appMessage" key="/admin/appMessage" exact app={this} component={appMessage} isAdminOnly/>
  //             <Route path="/admin/logs" key="/admin/logs" exact app={this} component={AdminLog} isAdminOnly/>
  //             <Route path="/admin/pending/rejects" key="/admin/pending/rejects" exact app={this} component={PendingRejects} isAdminOnly/>
  //             <Route path="/admin/users/manage" key="/admin/users/manage" exact app={this} component={UserList} isAdminOnly/>
  //             <Route path="/admin/users/manage/:id" key="/admin/users/manage/:id" exact app={this} component={User} isAdminOnly/>
  //             <Route path="/admin/connectedUsers/tail/:id" key="/admin/connectedUsers/tail/:id" exact app={this} component={UserTail} isAdminOnly/>
  //             <Route path="/admin/control" key="/admin/control" exact app={this} component={Control} isAdminOnly/>
  //             <Route path="/admin/users/premium" key="/admin/users/premium" exact app={this} component={PremUsersActive} isAdminOnly/>

  //             {/* Errors */}
  //             <Route path="/401" key="*" app={this} component={Err401}/>
  //             <Route path="*" key="*" app={this} component={Err404}/>
  //         </Switch>
  //         ):(<this.loaderPlace></this.loaderPlace>)}</Suspense></ScrollToTop></ErrorBoundary>
  //     </Router>
          
  //     <Snackbar open={this.state.toast} autoHideDuration={6000} onClose={this.handleClose}>
  //       <this.Alert onClose={this.handleClose} severity={this.state.toastSeverity}>
  //         <this.alertText></this.alertText>
  //       </this.Alert>
  //     </Snackbar>
  //     <Snackbar open={this.state.isOffline}>
  //       <this.Alert severity="warning">
  //         {this.state.offlineMsg}
  //       </this.Alert>
  //     </Snackbar>
  //     <Snackbar open={this.state.reboot}>
  //       <this.Alert severity="warning">
  //         {this.state.rebootMessage}
  //       </this.Alert>
  //     </Snackbar>
  //     </main>
  //           {/* Footer */}
  //           </ThemeProvider>
  //           {/* End footer */}
  //         </React.Fragment>
  //   )

  // }
}