import * as React from "react";
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Link } from "react-router-dom";
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import { makeStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PermScanWifiIcon from '@material-ui/icons/PermScanWifi';
import FaceIcon from '@material-ui/icons/Face';

const useStyles = makeStyles(theme=>({
    small: {
        width: theme.spacing(5),
        height: theme.spacing(5),
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    placeHolder:{
        width: theme.spacing(18),
        height: theme.spacing(4),
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default class User extends React.Component {
    constructor(props){
        super(props);
        this.profileMenu=this.profileMenu.bind(this);
        this.logOut=this.logOut.bind(this);
        this.isLoggedin=false;
    }

    logOut(){
        this.props.app.socket.emit("tmx.user.logout");
        //legacy logout just incase
        fetch('https://thememyxbox.net/ws_react.php?format=json&method=pwg.session.logout',{
            credentials: 'include',
        })
      
    }

    onLineLoggedInMenu=()=>{
        const classes=useStyles();
        const user=this.props.app.state.user;
        const [userMenuOpen, setOpen] = React.useState(false);
        const handleClick = () => {
            setOpen(!userMenuOpen);
        };
        var mobileOpen=this.props.app.state.mobileOpen;
        const handleDrawerToggle = () => {
            this.props.app.setMobileOpen(!mobileOpen);
        };
        return(
            <div>
                <ListItem button onClick={handleClick}>
                    <ListItemIcon>
                        <Avatar alt={user.username} src={"https://thememyxbox.net/towebp.php?noCache=true&src="+user.profilepic} className={classes.small} />
                    </ListItemIcon>
                    <ListItemText primary={"Hello, "+ user.username+"!"} />
                    {userMenuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>        
                <Collapse in={userMenuOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button className={classes.nested} component={Link} to='/user/profile'>
                            <ListItemIcon onClick={handleDrawerToggle}>
                                <FaceIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Profile" onClick={handleDrawerToggle}/>
                        </ListItem>
                        <ListItem button className={classes.nested} onClick={this.logOut}>
                            <ListItemIcon onClick={handleDrawerToggle}>
                                <LockOpenIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Logout" onClick={handleDrawerToggle}/>
                        </ListItem>
                    </List>
                </Collapse>
            </div>
        )
    }

    onlineLoggedOutMenu=()=>{
        const classes=useStyles();
        const user=this.props.app.state.user;
        var mobileOpen=this.props.app.state.mobileOpen;
        const [userMenuOpen, setOpen] = React.useState(false);
        const handleDrawerToggle = () => {
            this.props.app.setMobileOpen(!mobileOpen);
        };
        const handleClick = () => {
            setOpen(!userMenuOpen);
        };
        return(
            <div>
                <ListItem button onClick={handleClick}>
                    <ListItemIcon>
                        <Avatar alt={user.username} src={user.profilepic} className={classes.small} />
                    </ListItemIcon>
                    <ListItemText primary={"Hello, "+ user.username+"!"} />
                    {userMenuOpen ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={userMenuOpen} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button className={classes.nested} component={Link} to="/user/login" onClick={handleDrawerToggle}>
                            <ListItemIcon>
                                <LockIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Login" />
                        </ListItem>
                        <ListItem button className={classes.nested} component={Link} to="/user/register" onClick={handleDrawerToggle}>
                            <ListItemIcon>
                                <PersonAddIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Register" />
                        </ListItem>
                    </List>
                </Collapse>
            </div>
        )
    }

    offlineMenu=()=>{
        const classes=useStyles();
        var mobileOpen=this.props.app.state.mobileOpen;
        const handleDrawerToggle = () => {
            this.props.app.setMobileOpen(!mobileOpen);
        };
        return(
            <ListItem>
                <ListItemIcon>
                    <PermScanWifiIcon/>
                </ListItemIcon>
                <ListItemText primary={"Offline Mode"} secondary="Limited Functionality" />
            </ListItem>
        )
    }

    profileMenu(){
        const classes=useStyles();
        if(this.props.app.state.isLoggedin){
            return(<this.onLineLoggedInMenu/>);
        }else if(this.props.app.state.isOffline){
            return(<this.offlineMenu/>)
        }else{
            return(<this.onlineLoggedOutMenu/>);
        }
    }

    profileMenuLoad(){
        const classes=useStyles();
        return(
            <ListItem>
                <ListItemIcon>
                    <Skeleton variant="circle" className={classes.small} /><br></br>
                </ListItemIcon>
                <Skeleton variant="rect" className={classes.placeHolder} />
            </ListItem>
        )
    }

    render(){
        const isLoaded = this.props.app.state.isLoaded;
        if(isLoaded){
            return(
                <this.profileMenu></this.profileMenu>
            )
        } else {
            return(
                <this.profileMenuLoad></this.profileMenuLoad>
            )
        }

    }


}