import React, { useState, useEffect, Suspense, lazy } from 'react';

const PendingProfiles = lazy(()=> import('../../admin/pending_profile'))
const Pending = lazy(()=>import('../../admin/pending'))
const Reported = lazy(()=>import('../../admin/reported'))
const ReportedView = lazy(()=>import('../../admin/reportedView'))
const Stats = lazy(()=>import('../../admin/stats'))


{/*
<Route path="/admin/connectedUsers" key="/admin/connectedUsers" exact app={this} component={ConnectedList} isModOnly/>
 */}

const ModRoutes =[
    {
        path:'/admin/users/pending_profile',
        component:PendingProfiles,
        isAdminOnly:false, 
        isModOnly:true, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/admin/pending',
        component:Pending,
        isAdminOnly:false, 
        isModOnly:true, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/admin/reported',
        component:Reported,
        isAdminOnly:false, 
        isModOnly:true, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/admin/reported/:imgid',
        component:ReportedView,
        isAdminOnly:false, 
        isModOnly:true, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/admin/stats',
        component:Stats,
        isAdminOnly:false, 
        isModOnly:true, 
        isPrivate:false, 
        exact:true
    }
]
export default ModRoutes