import React from 'react';
import { Link } from "react-router-dom";

import { fade, makeStyles, useTheme  } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Drawer, List, Divider, ListItem, ListItemText, ListItemIcon, CssBaseline, Hidden, Grid }  from '@material-ui/core/';

import { Menu as MenuIcon, AddPhotoAlternate as AddPhotoIcon, Home as HomeIcon, NewReleases as NewReleasesIcon } from '@material-ui/icons/';
import User from './usermenu';
import Catlist from './catlist';
import StarsIcon from '@material-ui/icons/Stars';
import tmx from '../assets/tmx.webp';
import AdminMenu from './adminmenu';
import HelpIcon from '@material-ui/icons/Help';
import Autocomplete from './search';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import InfoIcon from '@material-ui/icons/Info';
import { Icon, InlineIcon } from '@iconify/react';
import discordIcon from '@iconify-icons/mdi/discord';
import facebookIcon from '@iconify-icons/mdi/facebook';
import twitterIcon from '@iconify-icons/mdi/twitter';
import youtubeIcon from '@iconify-icons/mdi/youtube';
import trophyIcon from '@iconify-icons/mdi/trophy';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';

const drawerWidth = 240;
const useStyles = makeStyles(theme=>({
  root: {
    display: 'flex',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },

  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  grow: {
    flexGrow: 1,
  },
  nested: {
    paddingLeft: theme.spacing(3),
    
  },
  nested2: {
    paddingLeft: theme.spacing(6),
    
  },
  nestedText:{
    fontSize:'10'
  },
  nestedText2:{
    fontSize:'6'
  },
  logo:{
    position:'absolute',
    zIndex:11,
    top:0,
    left: '250px'
  }
}));

function ResponsiveDrawer(props) {
  const classes = useStyles();
  const { container } = props;
  const theme = useTheme();
  var mobileOpen=props.app.state.mobileOpen;
  const handleDrawerToggle = () => {
    props.app.setMobileOpen(!mobileOpen);
  };
  const drawer =  (
    <div>
      <User {...props}></User>
      <Divider />
      <List>
        <ListItem key="search">
          <Autocomplete app={props.app} toggle={handleDrawerToggle}/>
        </ListItem>
      </List>
      <Divider />
      <List>

        {/* ADMIN ONLY MENU*/}
        {props.app.state.user && (props.app.state.user.groups.hasOwnProperty("Admin") || props.app.state.user.groups.hasOwnProperty("Moderator")) ? (
          <AdminMenu classes={classes}  app={props.app} drawerToggle={handleDrawerToggle}/>
        ):(null)}
      </List>
      
      <List>
      {/* <ListItem key="search">
          <Autocomplete app={props.app} toggle={handleDrawerToggle}/>
        </ListItem> */}

      <ListItem key="home" button component={Link} to='/' onClick={handleDrawerToggle}>
          <ListItemIcon>
            <HomeIcon/>
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
        {!props.app.state.isOffline && props.app.state.isLoggedin ? (
          <ListItem button component={Link} to="/myfeed" onClick={handleDrawerToggle}>
            <ListItemIcon>
                <DynamicFeedIcon/>
            </ListItemIcon>
            <ListItemText primary="Feed" />
          </ListItem>
        ):(null)}
        <ListItem key="recent" button component={Link} to='/recent' onClick={handleDrawerToggle}>
          <ListItemIcon>
            <NewReleasesIcon/>
          </ListItemIcon>
          <ListItemText primary="Recent" />
        </ListItem>
        <ListItem key="popular" button component={Link} to='/popular' onClick={handleDrawerToggle}>
          <ListItemIcon>
            <WhatshotIcon/>
          </ListItemIcon>
          <ListItemText primary="Popular" />
        </ListItem>
        <ListItem key="top" button component={Link} to='/top' onClick={handleDrawerToggle}>
          <ListItemIcon>
            <Icon icon={trophyIcon} height="25" width="25"/>
          </ListItemIcon>
          <ListItemText primary="Top Contributors" />
        </ListItem>
        <Catlist {...props} key="catListAppBar"> </Catlist>
      </List>
      <Divider />
      {!props.app.state.isOffline && props.app.state.isLoggedin ? (
        <List>
          <ListItem button component={Link} to="/favorites" onClick={handleDrawerToggle}>
            <ListItemIcon>
                <StarsIcon/>
            </ListItemIcon>
            <ListItemText primary="My Favorites" />
          </ListItem>
          <ListItem key="upload" button component={Link} to='/upload' disabled={props.app.state.isOffline} onClick={handleDrawerToggle}>
            <ListItemIcon>
              <AddPhotoIcon/>
            </ListItemIcon>
            <ListItemText primary="Upload" />
          </ListItem>
          <ListItem button component={Link} to="/support" onClick={handleDrawerToggle}>
            <ListItemIcon>
                <HelpIcon/>
            </ListItemIcon>
            <ListItemText primary="Support" />
          </ListItem>
          <Divider />
          <ListItem button component={Link} to="/donate" onClick={handleDrawerToggle}>
            <ListItemIcon>
                <MonetizationOnIcon/>
            </ListItemIcon>
            <ListItemText primary="Donate" />
          </ListItem> 
          <ListItem button component={Link} to="/about" onClick={handleDrawerToggle}>
            <ListItemIcon>
                <InfoIcon/>
            </ListItemIcon>
            <ListItemText primary="About us" />
          </ListItem> 
        </List>
   ):(<List>
        <ListItem button component={Link} to="/donate" onClick={handleDrawerToggle}>
            <ListItemIcon>
                <MonetizationOnIcon/>
            </ListItemIcon>
            <ListItemText primary="Donate" />
        </ListItem>  
        <ListItem button component={Link} to="/about" onClick={handleDrawerToggle}>
            <ListItemIcon>
                <InfoIcon/>
            </ListItemIcon>
            <ListItemText primary="About us" />
        </ListItem>    
      </List>)
        }
        <Divider/>
      <Grid container style={{paddingTop:"5px"}}>
        <Grid item xs={12} style={{padding:"-5px",textAlign: "center"}} alignContent="center">
          <Link to={{pathname:"https://discord.gg/96S8chF"}} style={{textDecoration:"none",color:"inherit",margin:"2px"}} target="_blank"><Icon icon={discordIcon} width="32" height="32" /></Link>
          <Link to={{pathname:"https://www.facebook.com/thememyxbox"}} style={{textDecoration:"none",color:"inherit",margin:"2px"}} target="_blank"><Icon icon={facebookIcon} width="32" height="32" /></Link>
          <Link to={{pathname:"https://twitter.com/ThemeMyXbox"}} style={{textDecoration:"none",color:"inherit",margin:"2px"}} target="_blank"><Icon icon={twitterIcon} width="32" height="32" /></Link>
          <Link to={{pathname:"https://www.youtube.com/c/TMXApp"}} style={{textDecoration:"none",color:"inherit",margin:"2px"}} target="_blank"><Icon icon={youtubeIcon} width="32" height="32" /></Link>
        </Grid>
      </Grid>
      <div style={{position:"relative", bottom:"-15px", width:"100%",textAlign:"center"}}>
        Site version:<br/>{props.app.state.version}
      </div>
      <div style={{position:"relative", bottom:"-15px", width:"100%",textAlign:"center"}}>
        Connected to server:<br/>{props.app.state.servername}<br/>
      </div>
    </div>
  );
    return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>

            <img src={tmx} width={82} height={35} color='transparent' alt="TMX Logo"/>

        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            {...props}
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
            
          >
            {drawer}

          </Drawer>
        </Hidden>
      </nav>
    </div>
  );
}

export default ResponsiveDrawer;