import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './registerServiceWorker';
import { ClearCacheProvider, useClearCacheCtx } from 'react-clear-cache';
import NewVersion from './helpers/versioncheck';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";


Sentry.init({
  dsn: "https://97585e526eff4041867f500a6086dc9c@o557370.ingest.sentry.io/5689500",
});

const VersionCheck =()=>{
  const { isLatestVersion, emptyCacheStorage, appVersion } = useClearCacheCtx();
  
      if(!isLatestVersion){
        return (<NewVersion doUpdate={emptyCacheStorage}/>)
      }else{
        return (<App isLatest={true} version={appVersion}/>)
      }
  
}

ReactDOM.render(
<ClearCacheProvider duration={3000} filename="xbuild.json">
  <VersionCheck/>
</ClearCacheProvider>
,
  document.getElementById('root')
);



//renderDom();
serviceWorker.register();
