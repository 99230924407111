import React, { useState, useEffect, Suspense, lazy } from 'react';

// import {  Register, Favorites, PwReset, PwReset2, Profile } from '../../components/user';
const PwReset2 = lazy(()=>import('../../user/pwreset_step2'))
const PwReset = lazy(()=>import('../../user/pwreset'))
const Register = lazy(()=>import('../../user/register'))
const Login = lazy(()=>import('../../user/login'))
const SSO= lazy(()=> import( '../../user/sso'));
const Search = lazy(()=> import('../../components/search'));

const Recent = lazy(()=> import('../../galleryViews/recent'));
const Popular = lazy(()=> import('../../galleryViews/popular'));
const Categories= lazy(()=> import('../../galleryViews/categories/main'));
const SubCategories= lazy(()=> import( '../../galleryViews/categories/subcats'));
const Images = lazy(()=> import('../../galleryViews/categories/imageLists'));

const News = lazy(()=> import('../../components/news'));
const Home= lazy(()=> import('../../components/home'));
const UserImages= lazy(()=> import('../../galleryViews/user_images'));
const Image= lazy(()=> import('../../components/image'));
const MyFeed= lazy(()=> import( '../../components/myfeed'));

const Donate= lazy(()=> import( '../../components/donate'));
const TopContribs= lazy(()=> import( '../../components/top'));
const About= lazy(()=> import( '../../components/about'));

const Err401= lazy(()=> import( '../../components/errors/unauthorized'));
const Err404= lazy(()=> import( '../../components/errors/notfound'));

//Public Routes
const PublicRoutes =[

    {
        path:'/',
        component:Home,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'categorielist',
        component:Categories,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/categories/:id',
        component:SubCategories,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/news',
        component:News,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/recent',
        component:Recent,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/popular',
        component:Popular,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/categories/images/:id',
        component:Images,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/categories/:id/image/:imgid',
        component:Image,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/search/:term',
        component:Search,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:false
    },
    {
        path:'/image/:imgid',
        component:Image,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/user/login/',
        component:Login,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/user/login/:location',
        component:Login,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:false
    },
    {
        path:'/user/password_reset',
        component:PwReset,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/user/password_reset/:resetkey',
        component:PwReset2,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:false
    },
    {
        path:'/user/register/',
        component:Register,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/user/register/:location',
        component:Register,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:false
    },
    {
        path:'/users/:id',
        component:UserImages,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/users/:id/:location',
        component:UserImages,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/user/sso/:ssoKey/:location',
        component:SSO,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:false
    },
    {
        path:'/donate',
        component:Donate,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/about',
        component:About,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:false
    },
    {
        path:'/top',
        component:TopContribs,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:false
    },
    {
        path:'/401',
        component:Err401,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'*',
        component:Err404,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:false, 
        exact:false
    },
]


export default PublicRoutes