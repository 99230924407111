import React, { useState, useEffect, Suspense, lazy } from 'react';

const SupportAdmin = lazy(()=> import('../../admin/support'))
const TicketAdmin = lazy(()=>import('../../admin/ticket'))
const FourKImages = lazy(()=>import('../../admin/4k'))
const PendingRejects = lazy(()=>import('../../admin/pending_rejects'))
const Control = lazy(()=>import('../../admin/control'))
const UserTail = lazy(()=>import('../../admin/user'))
const Pending = lazy(()=>import('../../admin/pending'))
const appMessage = lazy(()=>import('../../admin/appMessage'))
const User = lazy(()=>import('../../admin/user'))
const UserList = lazy(()=>import('../../admin/userlist'))
const PremUsersActive = lazy(()=>import('../../admin/premUserActive'))
const pendingRejects = lazy(()=>import('../../admin/pending_rejects'))
const AdminLog = lazy(()=>import('../../admin/admin_log'));
const Aproved = lazy(()=> import('../../admin/approved'));
const PendingProfiles = lazy(()=> import('../../admin/pending_profile'));

const AdminRoutes =[
    {
        path:'/admin/support/:id',
        component:SupportAdmin,
        isAdminOnly:true, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/admin/support/manage/:id',
        component:TicketAdmin,
        isAdminOnly:true, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/admin/assign4k',
        component:FourKImages,
        isAdminOnly:true, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/admin/appMessage',
        component:appMessage,
        isAdminOnly:true, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/admin/logs',
        component:AdminLog,
        isAdminOnly:true, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/admin/pending/rejects',
        component:PendingRejects,
        isAdminOnly:true, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/admin/users/manage',
        component:UserList,
        isAdminOnly:true, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/admin/users/manage/:id',
        component:User,
        isAdminOnly:true, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/admin/connectedUsers/tail/:id',
        component:UserTail,
        isAdminOnly:true, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/admin/control',
        component:Control,
        isAdminOnly:true, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
    {
        path:'/admin/users/premium',
        component:PremUsersActive,
        isAdminOnly:true, 
        isModOnly:false, 
        isPrivate:false, 
        exact:true
    },
]
export default AdminRoutes