import React from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { createMuiTheme, ThemeProvider, CssBaseline, Container } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CircularProgress from '@material-ui/core/CircularProgress';


const darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: green,
    },
    typography: {
      subtitle1: {
        fontSize: 12,
      }
    },
  });

const doReload=(elem,refresher)=>{
  elem._button=null;
  elem.setState({reloading:true})
  setTimeout(()=>{refresher()},1000)
}

const AlertDialogSlide=(props)=> {
    const elRef = React.createRef();
    props.elem._button=elRef;
    return (
      <Dialog
        open={true}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">{"Update available"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            An updated version of the app is available from the server. To get the latest update, select update now below
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>{doReload(props.elem,props.refresh)}} ref={elRef} variant="contained" autofocus color="primary">
            Update now
          </Button>
        </DialogActions>
      </Dialog>
  );
}

const Reloading=()=>{
  return(
    <Container maxWidth="xl"  style={{textAlign:"center", height:"100%", verticalAlign:"middle"}} >
      <CircularProgress size="25em"/><br/>Reloading... Please wait
    </Container>
  )
}

export default class NewVersion extends React.Component{
    constructor(){
        super()
        this._button=null;
        this.state={
            reloading:false
        }
    }

    render(){
      const { reloading } =this.state;

        return(
            <ThemeProvider theme={darkTheme}>
                <CssBaseline />
                <main>
                    {reloading ? (
                      <Reloading/>
                    ):(
                    <AlertDialogSlide refresh={this.props.doUpdate} elem={this}/>
                    )}
                </main>
            </ThemeProvider>
        )
    }
}