import React, { useState, useEffect, Suspense, lazy } from 'react';

const Profile = lazy(()=> import('../../user/profile'))
const Upload = lazy(()=>import('../../components/upload'))
const Favorites = lazy(()=>import('../../galleryViews/favorites'))
const SupportUser = lazy(()=>import('../../components/support/ticketlist'))
const SupportUserTicket = lazy(()=>import('../../components/support/ticket'))
const MyFeed = lazy(()=>import('../../components/myfeed'))

const PrivateRoutes =[
    {
        path:'/user/profile/',
        component:Profile,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:true, 
        exact:true
    },
    {
        path:'/user/profile/:location',
        component:Profile,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:true, 
        exact:true
    },
    {
        path:'/upload',
        component:Upload,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:true, 
        exact:true
    },
    {
        path:'/favorites',
        component:Favorites,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:true, 
        exact:true
    },
    {
        path:'/support',
        component:SupportUser,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:true, 
        exact:true
    },
    {
        path:'/support/:id',
        component:SupportUserTicket,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:true, 
        exact:true
    },
    {
        path:'/myfeed',
        component:MyFeed,
        isAdminOnly:false, 
        isModOnly:false, 
        isPrivate:true, 
        exact:true
    },

]
export default PrivateRoutes